<template lang="pug">
  form(@submit.prevent="onSubmit")
    .row
      .col-md-6
        .card
          h5.card-header {{ $t('panel.information.title') }}:

          .card-body
            .form-row
              .col-12
                .form-group
                  form-input(
                    id="name"
                    :model="$v.name"
                    :label="$t('form.name.label')"
                    :placeholder="$t('form.name.placeholder')"
                    :enabled="!locked"
                  )

            .form-row
              .col-12
                form-text-area(
                  id="description"
                  :model="$v.description"
                  :label="$t('form.description.label')"
                  :placeholder="$t('form.description.placeholder')"
                  :enabled="!locked"
                )

      .col-md-6
        .card
          h5.card-header {{ $t('panel.image.title') }}:

          .card-body
            image-bucket.rounded.mx-auto.d-block(
              bucket="garment"
              :uuid="garment"
              style="max-height: 200px;"
            )

            form-input-file-image(
              :model="$v.image"
              :search-text="$t('form.image.label')"
              :placeholder="$t('form.image.placeholder')"
              :enabled="!locked"
            )

    .row
      .col-md-6
        .card
          h5.card-header {{ $t('panel.sensors.title') }}:

          .card-body
            .row.mt-2.mb-2
              .col.ml-auto
                button(type="button" class="btn btn-sm btn-primary float-right" @click="add()")
                  fa(icon="plus")
                  = ' '
                  | {{ $t('sensors.button.add') }}

            .row.mb-2
              .col-3.text-center
                b {{ $t('sensors.table.uid.header') }}:

              .col-5.text-center
                b {{ $t('sensors.table.name.header') }}:

              .col-sm-2.text-center
                b {{ $t('sensors.table.color.header') }}:

              .col.ml-auto.text-right

            .row(v-for="(sensor, index) in $v.sensors.$each.$iter")
              .col-3
                form-input(id="sensor-uid" :model="sensor.uid" :placeholder="$t('sensors.table.uid.placeholder')")

              .col-5
                form-input(id="sensor-name" :model="sensor.name" :placeholder="$t('sensors.table.name.placeholder')")

              .col-2.text-center
                color-picker(v-model="sensor.color.$model" popover-x="left" swatches="text-advanced" max-height="25")

              .col-2.ml-auto
                button(type="button" class="btn btn-danger" @click="remove(sensor)" v-if="sensors.length > 1")
                  fa(icon="trash")

      .col-md-6
        .card
          h5.card-header {{ $t('panel.charts.title') }}:

          .card-body
            p.lead(v-html="$t('charts.description')")

            .row.align-items-center.mt-3(v-for="(config, codename) in charts")
              .col-10
                small
                  b {{ $t('charts.table.' + codename + '.name') }}:
                  .w-100
                  em.text-muted(v-html="$t('charts.table.' + codename + '.help')")

              .col-2.text-center
                .form-group.form-check
                  form-input-checkbox(:model="$v.charts[codename].enabled")

    .row(v-if="!garment")
      .col-md-12
        button(type="submit" class="btn btn-lg btn-primary float-right" :disabled="locked")
          span(v-if="!locked")
            span(v-if="!garment") {{ $t('form.submit.create.label') }}
            span(v-if="garment") {{ $t('form.submit.update.label') }}

          span(v-if="locked")
            button-spinner(color="#FFFFFF")

          span(v-if="!locked")
            fa(icon="angle-right")
</template>

<script>
import _ from 'lodash';
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import Duration from '@/components/common/Duration.vue'
import FormInput from '@/components/common/FormInput.vue'
import FormInputFile from '@/components/common/FormInputFile.vue'
import FormInputNumber from '@/components/common/FormInputNumber.vue'
import FormInputGroup from '@/components/common/FormInputGroup.vue'
import FormInputFileImage from '@/components/common/FormInputFileImage.vue';
import FormSelectGender from '@/components/common/FormSelectGender.vue'
import FormSelectIntensity from '@/components/common/FormSelectIntensity.vue'
import FormSelectActivity from '@/components/common/FormSelectActivity.vue'
import FormSelectGarment from '@/components/common/FormSelectGarment.vue'
import FormTextArea from '@/components/common/FormTextArea.vue'
import ListOperations from "@/components/common/ListOperations";
import http from '@/http/client';
import FormSelectFileVersion from "@/components/common/FormSelectFileVersion";
import FormInputCheckbox from "@/components/common/FormInputCheckbox";
import VSwatches from 'vue-swatches';
import 'vue-swatches/dist/vue-swatches.css'
import ImageBucket from "@/components/common/ImageBucket";
import color from '@/utils/color';

export default {
  components: {
    ImageBucket,
    FormInputCheckbox,
    FormSelectFileVersion,
    Duration,
    FormInput,
    FormInputFile,
    FormInputFileImage,
    FormInputNumber,
    FormInputGroup,
    FormTextArea,
    FormSelectGender,
    FormSelectIntensity,
    FormSelectActivity,
    FormSelectGarment,
    ListOperations,
    ColorPicker: VSwatches
  },

  props: {
    garment: {
      type: [ String ],
      default: null
    }
  },

  data() {
    return {
      locked: false,
      name: null,
      description: null,
      image: null,
      sensors: [
        {
          uid: 'Sensor1',
          name: 'Cuádriceps izquierdo',
          color: '#ff0000'
        },
        {
          uid: 'Sensor2',
          name: 'Cuádriceps derecho',
          color: '#0000ff'
        },
        {
          uid: 'Sensor3',
          name: 'Isquio derecho',
          color: '#e69138'
        },
        {
          uid: 'Sensor4',
          name: 'Isquio izquierdo',
          color: '#a4c2f4'
        }
      ],

      charts: {
        emgAbsolute: {
          enabled: true
        },
        emgPercentage: {
          enabled: true
        },
        mdBars: {
          enabled: true
        },
        mdRadar: {
          enabled: true
        },
        mdSymmetry: {
          enabled: true
        }
      }
    }
  },

  validations: {
    name: {
      required,
      minLength: minLength(5),
      maxLength: maxLength(255)
    },

    description: {
      maxLength: maxLength(65534)
    },

    image: {

    },

    sensors: {
      $each: {
        uid: {
          required,
          minLength: minLength(1)
        },
        name: {
          required,
          minLength: minLength(5)
        },
        color: {
          required
        }
      }
    },
    charts: {
      emgAbsolute: {
        enabled:  {}
      },
      emgPercentage: {
        enabled:  {}
      },
      mdBars: {
        enabled:  {}
      },
      mdRadar: {
        enabled:  {}
      },
      mdSymmetry: {
        enabled:  {}
      }
    }
  },

  methods: {
    load() {
      if(!this.garment) {
        return;
      }

      this.lock();
      http
        .get(`/garments/${this.garment}`)
        .then((response) => _.assign(this, response.data))
        .finally(() => this.unlock())
    },

    lock() {
      this.locked = true;
    },

    unlock() {
      this.locked = false;
    },

    toggleOutliersAdvancedConfig() {
      this.filters.outliers.advanced = !this.filters.outliers.advanced;
    },

    add() {
      const next = this.sensors.length + 1;
      let uid = `Sensor${next}`;

      if(_.find(this.sensors, { uid: uid })) {
        uid += '_(2)';
      }

      this.sensors.push({ uid: uid, name: null, color: color.random() });
    },

    remove(sensor) {
      this.sensors = _.remove(this.sensors, (current) => {
        return current.uid !== sensor.uid.$model;
      });
    },

    onSubmit() {
      this.$v.$touch();

      if (this.$v.$anyError) {
        console.info('There is some issue in form. Please review it!', this.$v);
        return;
      }

      const data = new FormData();
      data.append('name', this.name);
      data.append('description', this.description);
      data.append('sensors', JSON.stringify(this.sensors));
      data.append('charts', JSON.stringify(this.charts));
      data.append('imageFile', this.image);

      this.lock();

      http
        .post('/garments', data, {}, { json: false })
        .then(() => this.$router.push({ name: 'garments_list' }))
        .catch()
        .finally(() => this.unlock())
      ;
    }
  },

  mounted() {
    this.load();
  }
}
</script>

<i18n>
{
  "es": {
    "panel": {
      "information": {
        "title": "Información"
      },
      "image": {
        "title": "Imagen"
      },
      "sensors": {
        "title": "Sensores de la prenda"
      },
      "charts": {
        "title": "Gráficos asociados"
      }
    },
    "sensors": {
      "button": {
        "add": "Añadir sensor"
      },
      "table": {
        "uid": {
          "header": "ID",
          "placeholder": "Identificador único"
        },
        "name": {
          "header": "Nombre",
          "placeholder": "Nombre amigable"
        },
        "color": {
          "header": "Color",
          "placeholder": "Color en la serie de datos"
        }
      }
    },
    "charts": {
      "description": "Los gráficos que estén activados se mostrarán en los informes generados con esta prenda. Cada gráfico incluirá la serie de datos de cada sensor:",
      "table": {
        "emgAbsolute": {
          "name": "Electromiografía absoluta",
          "help": "Muestra un gráfico en línea de tiempo con los valores en absoluto."
        },
        "emgPercentage": {
          "name": "Electromiografia en porcentajes",
          "help": "Muestra un gráfico en línea de tiempo en relación al valor máximo de la serie."
        },
        "mdBars": {
          "name": "Distribución muscular en porcentajes",
          "help": "Muestra el nivel de \"esfuerzo\" que han recogido cada uno de los sensores y los compara en un gráfico de barras."
        },
        "mdRadar": {
          "name": "Radar de distribución muscular",
          "help": "Muestra en un gráfico de radar que músculo ha realizado más \"esfuerzo\"."
        },
        "mdSymmetry": {
          "name": "Índice de simetría muscular",
          "help": "Muestra en un gráfico circular qué músculo ha realizado más esfuerzo. Especialmente indicado para 2 sensores."
        }
      }
    },
    "form": {
      "name": {
        "label": "Nombre de la prenda",
        "placeholder": "Escribe el nombre de la prenda"
      },
      "description": {
        "label": "Descripción de la prenda",
        "placeholder": "Escribe una pequeña descripción de la prenda (opcional)"
      },
      "image": {
        "label": "Buscar",
        "placeholder": "Imagen de la prenda (opcional)"
      },
      "submit": {
        "create": {
          "label": "Crear prenda"
        },
        "update": {
          "label": "Actualizar prenda"
        }
      }
    }
  }
}
</i18n>
