<template lang="pug">
  div
    .form-row
      .col-12.text-center
        .image-preview(v-if="imageData.length > 0" )
          img.preview(:src="imageData" style="max-height: 300px; max-width: 300px;")

    .form-row.mt-3
      .col-12
        div.form-group
          label(for="idComplete" v-if="label") {{ label }}:

          .custom-file
            input(
              type="file"
              class="custom-file-input"
              :id="idComplete"
              :disabled="!stateEnabled"
              accept='image/*'
              @change="onPreviewImage"
            )

            label.custom-file-label(for="idComplete" :data-browse="searchText") {{ statePlaceholder }}
              span(v-if="model.$model") {{ model.$model.name }}
</template>

<script>
import FormInputFile from "@/components/common/FormInputFile.vue";

export default {
  mixins: [ FormInputFile ],

  data() {
    return {
      imageData: ''
    }
  },

  methods: {
    onPreviewImage() {
      // Reference to the DOM input element
      const input = event.target;

      // Ensure that you have a file before attempting to read it
      if (input.files && input.files[0]) {
        // create a new FileReader to read this image and convert to base64 format
        const reader = new FileReader();

        // Define a callback function to run, when FileReader finishes its job
        reader.onload = (e) => {
          // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
          // Read image as base64 and set to imageData
          this.imageData = e.target.result;
        }

        // Start the reader job - read file as a data url (base64 format)
        reader.readAsDataURL(input.files[0]);

        this.onChange(event);
      }
    }
  }

}
</script>
