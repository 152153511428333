<style lang="scss">
.clickable {
  cursor: pointer;
}
</style>

<template lang="pug">
  .card.card-solid
    .card-body.p-0
      .row
        .col-12
          table.table.table-hover.table-sm.m-0
            thead
              tr
                th {{ $t('table.header.name') }}
                th {{ $t('table.header.sensors') }}
                th {{ $t('table.header.image') }}
                th

            tbody(v-if="loading")
              td.text-center(colspan="6")
                .p-5
                  spinner(style="width: 3rem; height: 3rem;")

            tbody(v-else)
              tr(v-for="garment in garments" class="clickable" @click="open(garment)")
                td.align-middle {{ garment.name }}
                td.align-middle {{ garment.sensors.length }}

                td.align-middle(v-if="garment.hasImage")
                  image-bucket.rounded.d-block(bucket="garment" :uuid="garment.uuid" style="max-height: 100px;")

                td.align-middle(v-else)
                  em {{ $t('table.body.noImage') }}

                td.align-middle
                  list-operations(:show-remove="false" @open="open(garment)")
</template>

<script>
import ListOperations from "@/components/common/ListOperations";
import http from '@/http/client';
import ImageBucket from "@/components/common/ImageBucket";

export default {
  components: {
    ImageBucket,
    ListOperations
  },

  data() {
    return {
      loading: false,
      garments: []
    }
  },

  methods: {
    load() {
      this.loading = true;
      return http
        .get('/garments')
        .then((response) => this.garments = response.data)
        .finally(() => this.loading = false)
      ;
    },

    add() {
      this.$router.push({ name: 'garments_create' })
    },

    open(garment) {
      this.$router.push({ name: 'garments_edit', params: { garment: garment.uuid }});
    }
  },

  mounted() {
    this.load();
  }
}
</script>

<i18n>
{
  "es": {
    "table": {
      "header": {
        "name": "Nombre prenda",
        "sensors": "Número de sensores",
        "image": "Imagen"
      },
      "body": {
        "noImage": "Sin imagen"
      }
    }
  }
}
</i18n>
